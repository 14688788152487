var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.missions_data)?_c('div',{staticClass:"map-container"},[_c('div',{staticClass:"map"},[_c(_vm.map_path,{ref:"map_path",tag:"component",staticClass:"map_path"}),_vm._l((_vm.missions_data),function(mission,index){return _c('div',{key:`marker-${index}`,class:[
                'map_marker',
                {
                    'map_marker--past': index < _vm.current_mission_index || _vm.current_mission_index == null,
                    'map_marker--current': index == _vm.current_mission_index,
                    'map_marker--future': index > _vm.current_mission_index && _vm.current_mission_index,
                },
            ],style:(_vm.mission_markers_style[index]),on:{"click":function($event){return _vm.setActive(index)},"mouseover":function($event){return _vm.setActive(index)}}},[(_vm.current_mission_index && index > _vm.current_mission_index)?_c('lock',{staticClass:"lock-icon"}):_c('div',{staticClass:"map_marker_core"})],1)}),_vm._l((_vm.missions_data),function(mission,index){return _c('MapPopover',{key:`popover-${index}`,class:[
                {
                    'map_popover--past': index < _vm.current_mission_index,
                    'map_popover--current': index == _vm.current_mission_index,
                    'map_popover--future': index > _vm.current_mission_index,
                    'map_popover--reversed': _vm.map_config?.missions[index]?.reverse,
                },
            ],style:(_vm.mission_popover_style[index]),attrs:{"active":_vm.active == index,"config":mission,"index":index},on:{"deactivate":function($event){return _vm.setActive(null)},"click":function($event){return _vm.setActive(index)}}})})],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }