<template>
    <b-container class="main">
        <div class="main__grid">
            <div class="main__grid-item main__map">
                <al-card style="height: 100%" class="main__map-card" no-padding>
                    <AlMap
                        :missions_data="missions_data"
                        :current_mission_index="current_mission_index"
                        v-if="missions_data"
                    />
                </al-card>
            </div>

            <div class="main__grid-item main__info">
                <al-event-card
                    :event="nearestEvent"
                    :event-button-text="isEventStarting ? 'Смотреть трансляцию' : 'Узнать подробнее'"
                    @needToUpdateEvent="updateEventData"
                    v-if="nearestEvent?.id"
                >
                    <template #card-title>Ближайшее событие</template>
                    <template #event-title>{{ nearestEvent?.title ?? EMPTY_EVENT_TITLE }}</template>
                    <template #event-time v-if="nearestEvent?.id">
                        {{ formatEventDate(nearestEvent.begin_at, nearestEvent.end_at) }}
                    </template>
                    <template #event-description v-if="nearestEvent?.id">
                        {{ nearestEvent.description }}
                    </template>
                </al-event-card>

                <al-card style="height: 100%" v-else>
                    <div class="empty-event-widget-message" v-if="missions_data">
                        {{ current_mission_index ? EMPTY_EVENT_TITLE : END_TITLE }}
                    </div>
                </al-card>
            </div>
        </div>

        <al-legend />
    </b-container>
</template>

<script>
import AlCard from '@/components/AlCard';
import AlEventCard from '@/components/AlEventCard';
import AlModal from '@/components/AlModal';
import AlLegend from '@/components/AlLegend';
import { formatEventDate } from '@/utils/helpers';
import AlMap from '@/components/map/AlMap';
import { add, isAfter, parseJSON, isBefore } from 'date-fns';
import EventApi from '@/services/event';
import MissionApi from '@/services/mission';

const EMPTY_EVENT_TITLE = 'Скоро здесь появится информация о\xa0новом событии';
const END_TITLE =
    'Курс завершён. Спасибо за\xa0участие. Записи лекций и дополнительные материалы остаются доступны на\xa0карте миссий';

export default {
    name: 'Main',
    components: { AlEventCard, AlCard, AlModal, AlLegend, AlMap },
    props: {
        showLegend: { type: Boolean | String, default: false },
    },
    computed: {
        isEventStarting() {
            return (
                this.nearestEvent?.id && !isAfter(parseJSON(this.nearestEvent.begin_at), add(Date.now(), { hours: 24 }))
            );
        },
    },
    data() {
        return {
            nearestEvent: {},
            missions_data: null,
            current_mission_index: 6,
            EMPTY_EVENT_TITLE,
            END_TITLE,
        };
    },
    async mounted() {
        if (this.showLegend) {
            this.$bvModal.show('legend-modal');
        }

        await this.updateEventData();
        await this.updateMissionsData();
    },

    methods: {
        formatEventDate,
        async updateEventData() {
            this.nearestEvent = await EventApi.getNearestEvent();
        },
        async updateMissionsData() {
            this.missions_data = await MissionApi.getAllMissionsInfo().then(data => {
                return data.sort((a, b) => a.begin_at.localeCompare(b.begin_at)).slice(0, 5);
            });

            let result = this.missions_data
                .map((mission, index) => {
                    return {
                        begin_at: mission.begin_at,
                        end_at: mission.end_at,
                        index,
                    };
                })
                .filter(
                    mission =>
                        isBefore(parseJSON(mission.begin_at), parseJSON(Date.now())) &&
                        isAfter(parseJSON(mission.end_at), parseJSON(Date.now()))
                );

            console.log(result.map(f => f.index));
            this.current_mission_index = result[result.length - 1]?.index;
        },
    },
};
</script>
