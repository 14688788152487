import axios from 'axios';

class LegendApi {
    async getLegend() {
        try {
            const result = await axios.get('/legend');
            return result.data;
        } catch (err) {
            console.log(err);
            alert(`Ошибка запроса ${err.message}`);
        }
    }
}

export default new LegendApi();