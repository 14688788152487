<template>
    <div class="map-container" v-if="missions_data">
        <div class="map">
            <component class="map_path" ref="map_path" :is="map_path"></component>
            <div
                v-for="(mission, index) in missions_data"
                :key="`marker-${index}`"
                :class="[
                    'map_marker',
                    {
                        'map_marker--past': index < current_mission_index || current_mission_index == null,
                        'map_marker--current': index == current_mission_index,
                        'map_marker--future': index > current_mission_index && current_mission_index,
                    },
                ]"
                :style="mission_markers_style[index]"
                @click="setActive(index)"
                @mouseover="setActive(index)"
            >
                <lock v-if="current_mission_index && index > current_mission_index" class="lock-icon" />
                <div class="map_marker_core" v-else />
            </div>

            <MapPopover
                v-for="(mission, index) in missions_data"
                :key="`popover-${index}`"
                :style="mission_popover_style[index]"
                :active="active == index"
                :config="mission"
                :index="index"
                @deactivate="setActive(null)"
                @click="setActive(index)"
                :class="[
                    {
                        'map_popover--past': index < current_mission_index,
                        'map_popover--current': index == current_mission_index,
                        'map_popover--future': index > current_mission_index,
                        'map_popover--reversed': map_config?.missions[index]?.reverse,
                    },
                ]"
            />
        </div>
    </div>
</template>

<script>
import xs_path from '@/assets/images/map/xs/path.svg';
import sm_path from '@/assets/images/map/sm/path.svg';
import md_path from '@/assets/images/map/md/path.svg';
import lg_path from '@/assets/images/map/lg/path.svg';
import xl_path from '@/assets/images/map/xl/path.svg';

import lock from '@/assets/images/map/lock.svg';

import MapPopover from './MapPopover.vue';

import map_resolutions_config from './config.json';

export default {
    props: {
        missions_data: {
            required: true,
            type: Array,
        },
        current_mission_index: {
            type: [Number, null],
        },
    },
    data() {
        return {
            active: null,
        };
    },

    computed: {
        map_config() {
            return map_resolutions_config.resolutions.find(
                r => r.resolution_key == (this.$mq == 'xxl' ? 'xl' : this.$mq)
            );
        },
        map_path() {
            return `${this.$mq == 'xxl' ? 'xl' : this.$mq}_path`;
        },

        mission_markers_style() {
            if (this.map_config) {
                return this.map_config.missions.map(m => {
                    return {
                        top: `${(m.y / this.map_config.size.height) * 100}%`,
                        left: `${(m.x / this.map_config.size.width) * 100}%`,
                    };
                });
            } else {
                return [];
            }
        },

        mission_popover_style() {
            return this.map_config.missions.map(m => {
                if (this.$mq != 'xs' && this.$mq != 'sm') {
                    return {
                        bottom: `${(1 - m.y / this.map_config.size.height) * 100}%`,
                        right: `${(1 - m.x / this.map_config.size.width) * 100}%`,
                    };
                } else {
                    return {};
                }
            });
        },
    },

    components: {
        xs_path,
        sm_path,
        md_path,
        lg_path,
        xl_path,
        lock,
        MapPopover,
    },

    methods: {
        checkMapPath() {
            try {
                let segments = Array.from(this.$refs['map_path'].getElementsByTagName('path'));
                segments.forEach((a, i) => {
                    if (this.current_mission_index == null || this.current_mission_index > i) {
                        a.classList.add('segment-complete');
                    }
                });
            } catch (e) {}
        },

        setActive(index) {
            this.active = index;
        },
    },

    watch: {
        map_path: {
            handler(val) {
                this.$nextTick(() => {
                    this.checkMapPath();
                });
            },
        },
        missions_data: {
            immediate: true,
            handler() {
                this.$nextTick(() => {
                    this.checkMapPath();
                    this.active = this.current_mission_index;
                });
            },
        },
    },
};
</script>
