<template>
  <al-modal
      modal-id="legend-modal"
      modal-size="xl"
      centered
      no-close
      hide-header
      no-padding
  >
    <div class="al-legend">
      <div class="al-legend__body" v-show="step === 0">
        <div class="al-legend__image">
          <img src="@/assets/images/mascote-agent-a.png" alt="">
          <div class="al-legend__agent-info">
            <h3 class="al-legend__agent-title">Агент А</h3>
            <span class="al-legend__agent-subtitle">директор Секретной Службы</span>
          </div>
        </div>
        <div class="al-legend__info">
          <div class="al-legend__description">
          <h3 class="al-legend__title">Легенда</h3>
            <div class="al-legend__text" v-if="legend?.text">{{ legend.text }}</div>
            <div class="al-legend__text" v-else>
              Возможно, раньше вы не слышали о Секретной Службе, но мы, цифровые агенты, всегда держали
              руку на пульсе современных технологий. Благодаря нашей работе Альфа-Банк оказался на острие
              прогресса. Вы спросите, чем мы занимаемся? Я отвечу — добываем и анализируем секретные
              данные, находим уникальные решения для бизнеса и внедряем их в рабочие процессы.<br /><br />

              Но с недавних пор наша работа под угрозой. Корпорация «Омега» популяризирует «теорию
              регресса», следуя которой, цифровые технологии отбирают работу у людей. Наши аналитики
              прогнозируют, что в ближайшее время эта теория начнёт распространяться даже среди
              сотрудников нашего Банка.<br /><br />

              Как директор Секретной Службы, я принял решение укрепить штат наших агентов, чтобы
              противостоять корпорации «Омега». Рад приветствовать вас в наших рядах!
            </div>
          </div>
          <div class="al-legend__footer">
            <al-btn size="lg" class="al-legend__button" @click="nextStep">Начать свой путь!</al-btn>
          </div>

        </div>
      </div>

      <div class="al-legend__body al-legend__interactive" v-show="step >= 1">
        <al-typeform
            tf-widget="mZnflWDZ"
            tf-iframe-props="title=Альфа-Банк цифровой майндсет"
            width="100%"
            height="700px"
            :tf-hidden="`email=${getEmail}`"
        />
        <div class="al-legend__footer">
          <al-btn variant="link" size="lg" class="al-legend__button" @click="nextStep">Закрыть</al-btn>
        </div>
      </div>

    </div>

  </al-modal>
</template>
<script>
import AlModal from "@/components/AlModal";
import AlBtn from "@/components/AlBtn";
import AlTypeform from "@/components/AlTypeform";
import LegendApi from '@/services/legend';
import {mapGetters} from "vuex";

export default {
  name: 'AlLegend',
  components: {AlModal, AlBtn, AlTypeform},
  data(){
    return {
      step: 0,
      legend: {},
    }
  },
  computed: {
    ...mapGetters('auth', ['getEmail']),
  },

  async mounted() {
    this.legend = await LegendApi.getLegend();
  },

  methods: {
    nextStep(){
      if(this.step === 1) {
        this.$bvModal.hide('legend-modal');
      }
      this.step++;
    }
  }
}
</script>
