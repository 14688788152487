<template>
    <div class="al-event-card__container">
        <al-card no-padding class="al-event-card" overflow-hidden>
            <div class="al-event-card__heading">
                <h3 class="al-event-card__title">
                    <slot name="card-title" />
                </h3>
            </div>
            <div class="al-event-card__body">
                <div class="al-event-card__info">
                    <h3 class="al-event-card__event-title" lang="ru"><slot name="event-title" /></h3>
                    <div class="al-event-card__event-time">
                        <slot name="event-time" />
                    </div>
                </div>
                <div class="al-event-card__description">
                    <slot name="event-description" />
                </div>
                <div class="al-event-card__footer">
                    <template v-if="event?.id">
                        <al-btn
                            variant="primary"
                            size="lg"
                            class="al-event-card__subscribe-button"
                            :points="event.points_subscribe ?? 0"
                            v-if="!isEventStarting && !isEventFinished && !event.is_subscribed"
                            @click="openSubscribeModal"
                            >Записаться</al-btn
                        >
                        <al-btn
                            variant="link"
                            size="lg"
                            class="al-event-card__subscribe-button"
                            :points="event.points_subscribe ?? 0"
                            v-if="!isEventStarting && !isEventFinished && event.is_subscribed"
                            @click="unsubscribeFromEvent"
                            >Отписаться</al-btn
                        >

                        <al-btn
                            :to="`/event-online/${event.id}`"
                            variant="outline-primary"
                            size="lg"
                            class="al-event-card__watch-button"
                            :points="event.points_to_receive ?? 0"
                            v-if="!isEventStarting"
                            >Узнать подробнее</al-btn
                        >

                        <al-btn
                            :to="`/event-online/${event.id}`"
                            variant="primary"
                            size="lg"
                            class="al-event-card__watch-button"
                            v-if="isEventStarting"
                            >Смотреть трансляцию</al-btn
                        >
                    </template>
                </div>
            </div>
        </al-card>
        <al-subscribe-modal v-if="event?.id" :eventId="event.id" @needToUpdateEvent="$emit('needToUpdateEvent')" />
    </div>
</template>
<script>
import AlCard from '@/components/AlCard';
import AlBtn from '@/components/AlBtn';
import EventApi from '@/services/event';
import AlSubscribeModal from '@/components/AlSubscribeModal';

export default {
    name: 'AlEventCard',
    components: { AlBtn, AlCard, AlSubscribeModal },
    props: {
        event: { type: Object | Number },
        eventButtonText: { type: String, default: 'Узнать подробнее' },
    },
    computed: {
        isEventStarting() {
            return this.event.status == 1;
        },
        isEventFinished() {
            return this.event.status == 2;
        },
    },

    methods: {
        openSubscribeModal() {
            this.$bvModal.show('event-subscribe-modal');
        },

        async unsubscribeFromEvent() {
            const [err, result] = await EventApi.unsubscribeFromEventById(this.event.id);
            if (err) {
                alert('При отписке произошла ошибка, попробуйте ещё раз');
            }
            this.$emit('needToUpdateEvent');
        },
    },
};
</script>
