<template>
    <div :class="['map_popover', { 'map_popover--active': active }]" v-click-outside="deactivate">
        <div class="popover-container">
            <div class="content" v-show="active">
                <div class="content_title">
                    <div class="title_mascot"></div>
                    <div class="title_text">{{ config.title }}</div>
                </div>
                <div class="content_description">
                    <!-- <div> -->
                    <div>
                        {{ config.description }}
                    </div>
                    <AlBtn
                        variant="secondary"
                        :size="'default'"
                        :to="{ name: 'events-list', params: { missionId: config.id } }"
                        :points="config.points_to_receive"
                        v-if="config.events_count"
                        >К заданиям
                    </AlBtn>
                    <!-- </div> -->
                </div>
            </div>
            <div class="content--small" v-show="!active">{{ config.title }}</div>
        </div>
        <div class="popover-tail">
            <div class="plug"></div>
            <corner class="clip" />
        </div>
    </div>
</template>

<script>
import corner from '@/assets/images/map/corner.svg';
import ClickOutside from 'vue-click-outside';
import AlBtn from '@/components/AlBtn';
import AlBonusBadge from '@/components/AlBonusBadge';
export default {
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        config: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
        },
    },
    components: {
        corner,
        AlBtn,
        AlBonusBadge,
    },

    methods: {
        deactivate() {
            if (this.active) {
                if (this.$mq != 'xs' && this.$mq != 'sm') {
                    this.$emit('deactivate');
                }
            }
        },
    },

    directives: {
        ClickOutside,
    },
};
</script>
