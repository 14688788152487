<template>
    <div
        :data-tf-widget="tfWidget"
        :data-tf-iframe-props="tfIframeProps"
        :data-tf-medium="tfMedium"
        :style="`width: ${width}; height: ${height};`"
        :data-tf-hidden="tfHidden"
    />
</template>

<script>
import AlBtn from "@/components/AlBtn";
import AlModal from "@/components/AlModal";
export default {
  name: 'AlTypeform',
  components: {AlModal, AlBtn},
  props: {
    tfWidget: {type: String, required: true},
    tfIframeProps: {type: String, required: false, default: ''},
    tfMedium: {type: String, required: false, default: 'snippet'},
    tfHidden: {type: String, required: false, default: 'snippet'},
    width: {type: String, required: false, default: '100%'},
    height: {type: String, required: false, default: '100%'}
  },
  data(){
    return {
      tfScript: null
    }
  },

  mounted() {
    this.tfScript = document.createElement('script');
    this.tfScript.src = 'https://embed.typeform.com/next/embed.js'
    document.body.appendChild(this.tfScript)
  },

  beforeDestroy() {
    this.tfScript.remove();
  }

}
</script>
